import { TShirtSizeEstimate } from 'types'

export const formatIssueEstimate = (estimate: TShirtSizeEstimate) => {
  switch (estimate) {
    case TShirtSizeEstimate.XS:
      return 'Extra Small'
    case TShirtSizeEstimate.S:
      return 'Small'
    case TShirtSizeEstimate.M:
      return 'Medium'
    case TShirtSizeEstimate.L:
      return 'Large'
    case TShirtSizeEstimate.XL:
      return 'Extra Large'
    default:
      return estimate
  }
}
