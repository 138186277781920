import { PlusIcon, XMarkIcon } from '@heroicons/react/24/solid'
import { Button, Dialog, LabelText, Select, TextArea, TextInput } from 'components'
import React, { ComponentProps, useCallback, useEffect } from 'react'
import toast from 'react-hot-toast'
import { useNavigate, useParams } from 'react-router-dom'
import { FeaturePlan, Issue, IssuePriority, TShirtSizeEstimate } from 'types'

import { useEditingFeaturePlanIssue } from '@/featurePlans/hooks/useEditingFeaturePlanIssue'
import { useFeaturePlan } from '@/featurePlans/hooks/useFeaturePlan'
import { useUpdateFeaturePlan } from '@/featurePlans/hooks/useUpdateFeaturePlan'
import { formatIssueEstimate } from '@/featurePlans/utils/formatIssueEstimate'
import { formatIssueId } from '@/featurePlans/utils/formatIssueId'
import { formatIssuePriority } from '@/featurePlans/utils/formatIssuePriority'
import { formatIssueTitle } from '@/featurePlans/utils/formatIssueTitle'
import { makeIssue } from '@/featurePlans/utils/makeIssue'
import { FEATURE_PLAN_ID_PARAM, ISSUE_ID_PARAM, NEW_ISSUE_ID, routes } from '@/router/routes'

type SelectOption = ComponentProps<typeof Select>['options'][0]

const ISSUE_ESTIMATE_OPTIONS: SelectOption[] = [
  TShirtSizeEstimate.XS,
  TShirtSizeEstimate.S,
  TShirtSizeEstimate.M,
  TShirtSizeEstimate.L,
  TShirtSizeEstimate.XL,
].map(estimate => ({
  value: estimate,
  label: formatIssueEstimate(estimate),
}))
const ISSUE_PRIORITY_OPTIONS: SelectOption[] = [
  IssuePriority.NoPriority,
  IssuePriority.Urgent,
  IssuePriority.High,
  IssuePriority.Normal,
  IssuePriority.Low,
].map(priority => ({
  value: priority,
  label: formatIssuePriority(priority),
}))

export const EditFeaturePlanIssueDialog = () => {
  const { featurePlanId = '', issueId = '' } = useParams()
  const { data: featurePlan } = useFeaturePlan(featurePlanId)
  const { mutateAsync: updateFeaturePlan, isLoading: updateFeaturePlanLoading } = useUpdateFeaturePlan()

  const [editingIssue, setEditingIssue] = useEditingFeaturePlanIssue()

  const navigate = useNavigate()

  const isEditingIssue = Boolean(issueId !== NEW_ISSUE_ID)
  const submitDisabled = !editingIssue?.title || !editingIssue?.description || updateFeaturePlanLoading

  useEffect(() => {
    // if we are meant to be editing an issue, load the issue data, otherwise load a new issue
    if (!editingIssue) {
      if (isEditingIssue) {
        const issue = featurePlan?.issues?.find(issue => issue.id === issueId)

        if (issue) {
          setEditingIssue(issue)
        }
      } else {
        setEditingIssue(makeIssue())
      }
    }
  }, [editingIssue, featurePlan?.issues, isEditingIssue, issueId, setEditingIssue])

  const onClose = useCallback(() => {
    navigate(routes.back)

    setTimeout(() => {
      // clear the editing issue after the dialog is closed
      // we use a timeout to prevent the useEffect above firing before the dialog is closed
      setEditingIssue(null)
    }, 200)
  }, [navigate, setEditingIssue])

  const onSubmit = useCallback(async () => {
    if (!featurePlan) {
      throw new Error('Feature plan not found')
    }

    if (!editingIssue) {
      throw new Error('Editing issue not found')
    }

    const issues = isEditingIssue
      ? featurePlan?.issues?.map(issue => {
          if (issue.id === issueId) {
            return {
              ...issue,
              ...editingIssue,
            }
          }

          return issue
        })
      : [
          ...(featurePlan?.issues || []),
          {
            ...editingIssue,
            // get the next id based on the existing issues
            id: String(Math.max(0, ...(featurePlan?.issues || []).map(issue => Number(issue.id))) + 1),
          },
        ]

    const newFeaturePlan: FeaturePlan = {
      ...featurePlan,
      issues,
    }

    await updateFeaturePlan(newFeaturePlan)

    onClose()

    toast.success(isEditingIssue ? 'Issue added successfully' : 'Issue updated successfully')
  }, [editingIssue, featurePlan, isEditingIssue, issueId, onClose, updateFeaturePlan])

  if (!editingIssue) {
    return null
  }

  return (
    <>
      <Dialog open onClose={onClose}>
        <Dialog.Header title={isEditingIssue ? `Edit Issue ${formatIssueId(issueId)}` : 'Add Issue to Feature Plan'} />

        <TextInput
          label="Title"
          value={editingIssue.title}
          onChange={e => setEditingIssue({ ...editingIssue, title: e.target.value })}
        />

        <TextArea
          label="Description"
          value={editingIssue.description}
          onChange={e => setEditingIssue({ ...editingIssue, description: e.target.value })}
        />

        <Select
          label="Estimate"
          value={editingIssue.estimate}
          options={ISSUE_ESTIMATE_OPTIONS}
          onValueChange={option => setEditingIssue({ ...editingIssue, estimate: option.value as Issue['estimate'] })}
        />

        <Select
          label="Priority"
          value={editingIssue.priority}
          options={ISSUE_PRIORITY_OPTIONS}
          onValueChange={option => setEditingIssue({ ...editingIssue, priority: option.value as Issue['priority'] })}
        />

        <div className="flex flex-col gap-y-2">
          <LabelText>Blocked By</LabelText>

          <div className="flex flex-wrap items-center gap-2">
            {editingIssue.blockedBy
              // sort by issue id
              .sort((a, b) => a.localeCompare(b))
              .map(blockedIssueId => {
                const blockedIssue = featurePlan?.issues?.find(issue => issue.id === blockedIssueId)

                if (!blockedIssue) {
                  return null
                }

                return (
                  <Button
                    key={blockedIssueId}
                    className="max-w-[10rem] rounded-full"
                    variant="primaryNeutral"
                    size="sm"
                    icon={<XMarkIcon />}
                    iconPosition="right"
                    onClick={() =>
                      setEditingIssue({
                        ...editingIssue,
                        blockedBy: editingIssue.blockedBy.filter(id => id !== blockedIssueId),
                      })
                    }
                  >
                    {formatIssueTitle(blockedIssue)}
                  </Button>
                )
              })}

            <Button
              className="rounded-full"
              size="sm"
              icon={<PlusIcon />}
              onClick={() =>
                navigate(
                  routes.featurePlanIssueEditBlockedBy
                    .replace(FEATURE_PLAN_ID_PARAM, featurePlanId)
                    .replace(ISSUE_ID_PARAM, issueId),
                )
              }
            >
              Add
            </Button>
          </div>
        </div>

        <Dialog.Actions>
          <Button variant="lightNeutral" onClick={onClose}>
            Cancel
          </Button>

          <Button loading={updateFeaturePlanLoading} disabled={submitDisabled} onClick={onSubmit}>
            {isEditingIssue ? 'Save Changes' : 'Add Issue'}
          </Button>
        </Dialog.Actions>
      </Dialog>
    </>
  )
}
