import { Button, Dialog, NumberedList } from 'components'
import React, { ReactNode } from 'react'
import { Potion } from 'types'
import { useLink } from 'utils'

import { SlideContent } from './SlideContent'

const STEPS: ReactNode[] = [
  'In your Linear app, create a new issue with a sample feature request, such as "Add dark mode to the user settings."',
  <>
    Apply the <b>{Potion.PlanPotion}</b> label to the issue.
  </>,
  'Watch as Potion generates prioritized and codebase-aware sub-tasks, breaking down the request into manageable issues for your development team.',
]

type Props = {
  onContinue: () => void
}

// TODO: illustration/gif
export const UsageSlide = ({ onContinue }: Props) => {
  const link = useLink()

  return (
    <SlideContent
      title="Try Potion Now"
      description="Create a test issue in your Linear app and see how Potion transforms it into detailed, prioritized, and codebase-aware sub-tasks."
    >
      <NumberedList>
        {STEPS.map((step, index) => (
          <NumberedList.Item key={index} number={index + 1}>
            {step}
          </NumberedList.Item>
        ))}
      </NumberedList>

      <Dialog.Actions>
        <Button
          size="lg"
          onClick={() => {
            link('https://linear.app/login', '_blank')

            onContinue()
          }}
        >
          Open Linear
        </Button>
      </Dialog.Actions>
    </SlideContent>
  )
}
