import { Alert, Button, Dialog } from 'components'
import React from 'react'

import { ConnectLinearButton } from '@/components/connectLinearButton/ConnectLinearButton'
import { useUser } from '@/user/hooks/useUser'

import { SlideContent } from './SlideContent'

type Props = {
  onContinue: () => void
}

// TODO: illustration
export const ConnectProjectManagementToolSlide = ({ onContinue }: Props) => {
  const { data: user } = useUser()

  const isLinearConnected = user?.linearConnected

  return (
    <SlideContent
      title="Connect to Your Linear Project"
      description="To seamlessly integrate Potion's AI-generated feature plans into your team's development process, you'll need to connect your specific Linear project. This connection allows Potion to directly create and prioritize issues encapsulating the broken-down tasks and requirements within that Linear project."
    >
      {isLinearConnected && (
        <div className="flex justify-center">
          <Alert variant="success" className="w-auto">
            Linear connected successfully!
          </Alert>
        </div>
      )}

      <Dialog.Actions>
        {isLinearConnected ? (
          <Button size="lg" onClick={onContinue}>
            Continue
          </Button>
        ) : (
          <ConnectLinearButton size="lg" />
        )}
      </Dialog.Actions>
    </SlideContent>
  )
}
