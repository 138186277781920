import { Bars3Icon } from '@heroicons/react/24/solid'
import { Button, Headerbar } from 'components'
import React, { ComponentPropsWithoutRef } from 'react'
import { twMerge } from 'tailwind-merge'

import { features } from '@/features'
import { useSidebarOpen } from '@/sidebar/hooks/useSidebarOpen'

import { FeaturePlansTooltipHighlight } from './FeaturePlansTooltipHighlight'

type Props = ComponentPropsWithoutRef<'header'>

export const Header = ({ className = '', children }: Props) => {
  const [_, setSidebarOpen] = useSidebarOpen()

  const menuButton = (
    <Button
      variant="lightNeutral"
      className="lg:hidden"
      onClick={() => {
        setSidebarOpen(true)
      }}
    >
      <span className="sr-only">Open sidebar</span>

      <Bars3Icon className="h-6 w-6" aria-hidden="true" />
    </Button>
  )

  return (
    <Headerbar className={twMerge('', className)}>
      {features.featurePlanPreview ? (
        <FeaturePlansTooltipHighlight>{menuButton}</FeaturePlansTooltipHighlight>
      ) : (
        menuButton
      )}

      {children}
    </Headerbar>
  )
}
