import { Alert } from 'components'
import React from 'react'

import { ConnectLinearButton } from '@/components/connectLinearButton/ConnectLinearButton'
import { PageSection } from '@/components/pageSection/PageSection'
import { useUser } from '@/user/hooks/useUser'

import { DisconnectLinearButton } from './components/DisconnectLinearButton'

export const LinearSection = () => {
  const { data: user } = useUser()

  const isLinearConnected = user?.linearConnected

  return (
    <PageSection
      className="border-b-0"
      title="Linear"
      description="Connect your Linear account to enable Potion to generate issues in your workspace."
      action={isLinearConnected ? <DisconnectLinearButton /> : <ConnectLinearButton />}
    >
      {isLinearConnected && <Alert variant="success">Linear connected!</Alert>}
    </PageSection>
  )
}
