import { Cog6ToothIcon, DocumentIcon, EnvelopeOpenIcon, SquaresPlusIcon } from '@heroicons/react/24/solid'
import { Sidebar } from 'components'
import { app } from 'config'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useLink } from 'utils'

import { useHasActiveSubscription } from '@/billing/hooks/useHasActiveSubscription'
import { features } from '@/features'
import { useCreateFeaturePlanTooltipOpen } from '@/onboarding/hooks/useCreateFeaturePlanTooltipOpen'
import { useFeaturePlansTooltipOpen } from '@/onboarding/hooks/useFeaturePlansTooltipOpen'
import { useReleaseNotesDialogOpen } from '@/releaseNotes/hooks/useReleaseNotesDialogOpen'
import { routes } from '@/router/routes'
import { useSidebarOpen } from '@/sidebar/hooks/useSidebarOpen'

import pkg from '../../../../../package.json'
import { FeaturePlansTooltip } from './FeaturePlansTooltip'

export const AppSidebar = () => {
  const location = useLocation()
  const { data: hasActiveSubscription, isLoading: hasActiveSubscriptionLoading } = useHasActiveSubscription()
  const [sidebarOpen, setSidebarOpen] = useSidebarOpen()
  const [_1, setReleaseNotesDialogOpen] = useReleaseNotesDialogOpen()
  const [featurePlansTooltipOpen, setFeaturePlansTooltipOpen] = useFeaturePlansTooltipOpen()
  const [_2, setCreateFeaturePlanTooltipOpen] = useCreateFeaturePlanTooltipOpen()

  const navigate = useNavigate()
  const link = useLink()

  return (
    <>
      <Sidebar>
        {features.featurePlanPreview && (
          <FeaturePlansTooltip>
            <Sidebar.Item
              icon={<SquaresPlusIcon />}
              active={location.pathname === routes.featurePlans}
              disabled={features.subscriptions && (hasActiveSubscriptionLoading || !hasActiveSubscription)}
              onClick={() => {
                navigate(routes.featurePlans)

                if (sidebarOpen) {
                  setSidebarOpen(false)
                }

                // if the features tooltip was open, close it and open the create feature tooltip
                if (featurePlansTooltipOpen) {
                  setFeaturePlansTooltipOpen(false)
                  setCreateFeaturePlanTooltipOpen(true)
                }
              }}
            >
              Feature Plans
            </Sidebar.Item>
          </FeaturePlansTooltip>
        )}

        {(features.auth || features.subscriptions) && (
          <Sidebar.Item
            icon={<Cog6ToothIcon />}
            active={location.pathname.startsWith(routes.settings)}
            onClick={() => {
              navigate(routes.settings)

              if (sidebarOpen) {
                setSidebarOpen(false)
              }
            }}
          >
            Settings
          </Sidebar.Item>
        )}

        <Sidebar.Item
          icon={<EnvelopeOpenIcon />}
          onClick={() => {
            link(`mailto:${app.emails.support}`, '_blank')

            if (sidebarOpen) {
              setSidebarOpen(false)
            }
          }}
        >
          Feedback
        </Sidebar.Item>

        <div className="flex flex-1 flex-col justify-end space-y-2">
          <Sidebar.Item
            icon={<DocumentIcon />}
            onClick={features.releaseNotes ? () => setReleaseNotesDialogOpen(true) : undefined}
          >
            v{pkg.version} (beta)
          </Sidebar.Item>
        </div>
      </Sidebar>
    </>
  )
}
