import React from 'react'

import { GithubSection } from './components/githubSection/GithubSection'
import { LinearSection } from './components/linearSection/LinearSection'

export const SettingsIntegrations = () => {
  return (
    <div>
      <GithubSection />

      <LinearSection />
    </div>
  )
}
