import { Alert, Button, Dialog, SmallText } from 'components'
import React from 'react'
import { MAX_CONNECTED_REPOS } from 'types'

import { ConnectGithubButton } from '@/components/connectGithubButton/ConnectGithubButton'
import { useUser } from '@/user/hooks/useUser'

import { SlideContent } from './SlideContent'

type Props = {
  onContinue: () => void
}

// TODO: illustration
export const ConnectRepoSlide = ({ onContinue }: Props) => {
  const { data: user, isLoading: userLoading } = useUser()

  const isGithubConnected = user?.githubConnected

  return (
    <SlideContent
      title="Sync Potion with Your Codebase"
      description="For Potion's wizardry to properly understand the incantations within your codebase, you'll need to connect your GitHub repository(s). This allows our AI assistant to analyze your project's architecture and infuse the generated issues with implementation insight."
    >
      {isGithubConnected ? (
        <div className="flex justify-center">
          <Alert variant="success" className="w-auto">
            GitHub connected successfully!
          </Alert>
        </div>
      ) : (
        <SmallText className="text-center">
          *We currently allow a maximum of {MAX_CONNECTED_REPOS} connected repos.
        </SmallText>
      )}

      <Dialog.Actions>
        {isGithubConnected ? (
          <Button size="lg" onClick={onContinue}>
            Continue
          </Button>
        ) : (
          <ConnectGithubButton size="lg" disabled={userLoading} />
        )}
      </Dialog.Actions>
    </SlideContent>
  )
}
