import { Alert, AnchorText, SkeletonLoader, SmallText, Table } from 'components'
import React from 'react'
import { formatDate } from 'utils'

import { ConnectGithubButton } from '@/components/connectGithubButton/ConnectGithubButton'
import { PageSection } from '@/components/pageSection/PageSection'
import { useRepos } from '@/repos/hooks/useRepos'
import { useUser } from '@/user/hooks/useUser'

import { ConfigureGithubButton } from './components/ConfigureGithubButton'

const NUMBER_OF_COLUMNS = 3

export const GithubSection = () => {
  const { data: user, isLoading: userLoading } = useUser()
  const { data: repos, isLoading: reposLoading } = useRepos()

  const isGithubConnected = user?.githubConnected
  const isLoading = userLoading || reposLoading

  return (
    <PageSection
      className="pt-0 lg:pt-0"
      title="GitHub"
      description="Connect your GitHub account to enable Potion to analyze your codebase and generate issues with implementation insight."
      action={isGithubConnected ? <ConfigureGithubButton /> : <ConnectGithubButton />}
    >
      {isLoading ? (
        <SkeletonLoader className="h-10 w-48" />
      ) : (
        isGithubConnected && (
          <>
            <Alert variant="success">GitHub connected!</Alert>

            <Table>
              <Table.Head>
                <Table.Row>
                  <Table.HeaderCell>Connected Repository</Table.HeaderCell>

                  <Table.HeaderCell>Date Connected</Table.HeaderCell>

                  <Table.HeaderCell>Description</Table.HeaderCell>
                </Table.Row>
              </Table.Head>

              <Table.Body>
                {reposLoading ? (
                  <Table.Row>
                    {Array.from({ length: NUMBER_OF_COLUMNS }).map((_, index) => (
                      <Table.Cell key={index}>
                        <SkeletonLoader />
                      </Table.Cell>
                    ))}
                    <Table.Cell></Table.Cell>
                  </Table.Row>
                ) : repos?.length ? (
                  repos.map(repo => (
                    <Table.Row key={repo.id}>
                      <Table.Cell>
                        <AnchorText href={repo.url} target="_blank">
                          {repo.name}
                        </AnchorText>
                      </Table.Cell>

                      <Table.Cell>{formatDate(repo.createdAt)}</Table.Cell>

                      <Table.Cell className="max-w-xs truncate">{repo.description}</Table.Cell>
                    </Table.Row>
                  ))
                ) : (
                  <Table.Row>
                    <Table.Cell colSpan={NUMBER_OF_COLUMNS}>
                      <SmallText className="text-center">No repositories found.</SmallText>
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
          </>
        )
      )}
    </PageSection>
  )
}
