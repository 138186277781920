import { useMutation, useQueryClient } from '@tanstack/react-query'

import { QueryKeys } from '@/types'

import { exchangeGithubCodeForAccessToken } from '../api/exchangeGithubCodeForAccessToken'

export const useExchangeGithubCodeForAccessToken = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: exchangeGithubCodeForAccessToken,
    onSuccess: async () => {
      // refetch the user and repos queries to get the updated data
      await queryClient.invalidateQueries([QueryKeys.User])
      await queryClient.invalidateQueries([QueryKeys.Repos])
    },
  })
}
