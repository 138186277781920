import { useQuery } from '@tanstack/react-query'

import { useAuthUser } from '@/auth/hooks/useAuthUser'
import { QueryKeys } from '@/types'
import { useUser } from '@/user/hooks/useUser'

import { getRepos } from '../api/getRepos'

export const useRepos = () => {
  const { data: authUser } = useAuthUser()
  const { data: user } = useUser()

  const uid = authUser?.uid
  const githubConnected = user?.githubConnected

  const query = useQuery({
    queryKey: [QueryKeys.Repos],
    queryFn: () => (uid ? getRepos(uid) : undefined),
    enabled: Boolean(uid && githubConnected),
  })

  return {
    ...query,

    // for the loading state we use isFetching because the query may not be enabled yet if the authUser is not logged in
    isLoading: query.isFetching,
  }
}
