import React, { ComponentPropsWithoutRef } from 'react'
import { twMerge } from 'tailwind-merge'

import { Button, ButtonProps } from '../button/Button'

type StepProps = ButtonProps & {
  active?: boolean
}

const Step = ({ className = '', active, ...props }: StepProps) => {
  return (
    <Button {...props} className={twMerge('h-4 w-4 rounded-full p-0 shadow-none', active ? 'w-12' : '', className)} />
  )
}

type Props = ComponentPropsWithoutRef<'div'>

const Steps = ({ className = '', children, ...props }: Props) => {
  return (
    <div {...props} className={twMerge('flex gap-x-2', className)}>
      {children}
    </div>
  )
}

Steps.Step = Step

export { Steps }
