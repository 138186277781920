import { ErrorBoundary } from '@sentry/react'
import React, { Fragment } from 'react'
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
  RouterProvider,
} from 'react-router-dom'

import { AuthenticatedLayout } from '@/components/authenticatedLayout/AuthenticatedLayout'
import { MainLayout } from '@/components/mainLayout/MainLayout'
import { SettingsLayout } from '@/components/settingsLayout/SettingsLayout'
import { SubscriptionLayout } from '@/components/subscriptionLayout/SubscriptionLayout'
import { TeamAdminLayout } from '@/components/teamAdminLayout/TeamAdminLayout'
import { TeamPlanLayout } from '@/components/teamPlanLayout/TeamPlanLayout'
import { UnauthenticatedLayout } from '@/components/unauthenticatedLayout/UnauthenticatedLayout'
import { features } from '@/features'
import { FeaturePlans } from '@/pages/featurePlans'
import { CreateFeaturePlanDialog } from '@/pages/featurePlans/create/CreateFeaturePlanDialog'
import { FeaturePlan } from '@/pages/featurePlans/featurePlan'
import { DeleteFeaturePlanIssueDialog } from '@/pages/featurePlans/featurePlan/issueDelete/DeleteFeaturePlanIssueDialog'
import { SelectBlockedByIssueDialog } from '@/pages/featurePlans/featurePlan/issueEdit/blockedBy/SelectBlockedByIssueDialog'
import { EditFeaturePlanIssueDialog } from '@/pages/featurePlans/featurePlan/issueEdit/EditFeaturePlanIssueDialog'
import { ForgotPassword } from '@/pages/forgotPassword'
import { OAuthGithub } from '@/pages/oAuth/github'
import { SettingsAccount } from '@/pages/settings/account'
import { SettingsIntegrations } from '@/pages/settings/integrations'
import { SettingsSubscription } from '@/pages/settings/subscription'
import { SettingsInviteTeamMembers } from '@/pages/settings/teams/inviteTeamMembers'
import { SettingsTeam } from '@/pages/settings/teams/team'
import { SettingsTeamMember } from '@/pages/settings/teams/teamMember'
import { SettingsRemoveTeamMember } from '@/pages/settings/teams/teamMember/removeTeamMember'
import { SignIn } from '@/pages/signIn'
import { SignUp } from '@/pages/signUp'
import { UserManagement } from '@/pages/userManagement'

import { routes } from './routes'

export const DEFAULT_AUTHENTICATED_ROUTE = features.featurePlanPreview
  ? routes.featurePlans
  : routes.settingsIntegrations

const errorElement = <ErrorBoundary />

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      {features.auth && (
        <Route element={<UnauthenticatedLayout />} errorElement={errorElement}>
          <Route path={routes.signUp} element={<SignUp />} errorElement={errorElement} />

          <Route path={routes.signIn} element={<SignIn />} errorElement={errorElement} />

          <Route path={routes.forgotPassword} element={<ForgotPassword />} errorElement={errorElement} />

          <Route path={routes.userManagement} element={<UserManagement />} errorElement={errorElement} />

          <Route path="*" element={<Navigate to={routes.signIn} />} />
        </Route>
      )}

      <Route element={features.auth ? <AuthenticatedLayout /> : <Outlet />} errorElement={errorElement}>
        <Route element={<MainLayout />} errorElement={errorElement}>
          <Route element={features.subscriptions ? <SubscriptionLayout /> : <Outlet />} errorElement={errorElement}>
            <Route path={routes.oAuthGithub} element={<OAuthGithub />} errorElement={errorElement} />

            {features.featurePlanPreview && (
              <>
                <Route path={routes.featurePlans} element={<FeaturePlans />} errorElement={errorElement}>
                  <Route
                    path={routes.featurePlanCreate}
                    element={<CreateFeaturePlanDialog />}
                    errorElement={errorElement}
                  />
                </Route>

                <Route path={routes.featurePlan} element={<FeaturePlan />} errorElement={errorElement}>
                  <Route
                    path={routes.featurePlanIssueEdit}
                    element={<EditFeaturePlanIssueDialog />}
                    errorElement={errorElement}
                  />

                  <Route
                    path={routes.featurePlanIssueEditBlockedBy}
                    element={<SelectBlockedByIssueDialog />}
                    errorElement={errorElement}
                  />

                  <Route
                    path={routes.featurePlanIssueDelete}
                    element={<DeleteFeaturePlanIssueDialog />}
                    errorElement={errorElement}
                  />
                </Route>
              </>
            )}
          </Route>

          {(features.auth || features.subscriptions) && (
            <Route element={<SettingsLayout />} errorElement={errorElement}>
              <Route
                path={routes.settings}
                element={
                  <Navigate
                    to={
                      features.auth
                        ? routes.settingsAccount
                        : features.subscriptions
                        ? routes.settingsSubscription
                        : DEFAULT_AUTHENTICATED_ROUTE
                    }
                  />
                }
                errorElement={errorElement}
              />

              {features.auth && (
                <Route path={routes.settingsAccount} element={<SettingsAccount />} errorElement={errorElement} />
              )}

              {features.subscriptions && (
                <Route
                  path={routes.settingsSubscription}
                  element={<SettingsSubscription />}
                  errorElement={errorElement}
                />
              )}

              {features.teams && (
                <Route
                  element={features.subscriptions ? <SubscriptionLayout /> : <Outlet />}
                  errorElement={errorElement}
                >
                  <Route element={<TeamPlanLayout />} errorElement={errorElement}>
                    <Route path={routes.settingsTeam} element={<SettingsTeam />} errorElement={errorElement} />

                    <Route element={<TeamAdminLayout />} errorElement={errorElement}>
                      <Route
                        path={routes.settingsInviteTeamMembers}
                        element={<SettingsInviteTeamMembers />}
                        errorElement={errorElement}
                      />

                      <Route
                        path={routes.settingsTeamMember}
                        element={<SettingsTeamMember />}
                        errorElement={errorElement}
                      >
                        <Route
                          path={routes.settingsRemoveTeamMember}
                          element={<SettingsRemoveTeamMember />}
                          errorElement={errorElement}
                        />
                      </Route>
                    </Route>
                  </Route>
                </Route>
              )}

              <Route
                path={routes.settingsIntegrations}
                element={<SettingsIntegrations />}
                errorElement={errorElement}
              />
            </Route>
          )}
        </Route>
      </Route>
    </>,
  ),
)

export const Router = () => {
  return <RouterProvider router={router} />
}
