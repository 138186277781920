import { ParagraphText, TitleText } from 'components'
import React, { ComponentPropsWithoutRef, ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

type Props = ComponentPropsWithoutRef<'div'> & {
  // image: string // TODO: add image
  title: string
  description?: ReactNode
}

export const SlideContent = ({ className = '', title, description, children, ...props }: Props) => {
  return (
    <div className={twMerge('flex flex-col gap-y-6', className)} {...props}>
      <div className="flex flex-col items-center gap-y-6 text-center">
        <div className="bg-theme-background-subtle h-36 w-36 rounded-xl" />

        <TitleText>{title}</TitleText>

        <ParagraphText>{description}</ParagraphText>
      </div>

      {children}
    </div>
  )
}
