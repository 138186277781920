import { useCallback } from 'react'

export const useLink = () => {
  const link = useCallback((to: string, target?: string) => {
    console.log('Opening link:', to)

    window.open(to, target)
  }, [])

  return link
}
