import { Loading } from 'components'
import React, { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { features } from '@/features'
import { useExchangeGithubCodeForAccessToken } from '@/github/hooks/useExchangeGithubCodeForAccessToken'
import { useFeaturePlansTooltipOpen } from '@/onboarding/hooks/useFeaturePlansTooltipOpen'
import { useWelcomeDialogOpen } from '@/onboarding/hooks/useWelcomeDialogOpen'
import { DEFAULT_AUTHENTICATED_ROUTE } from '@/router/Router'

export const OAuthGithub = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { code, installation_id: installationId, setup_action: setupAction } = Object.fromEntries(searchParams)

  const { mutateAsync: exchangeGithubCodeForAccessToken } = useExchangeGithubCodeForAccessToken()
  const [_1, setWelcomeDialogOpen] = useWelcomeDialogOpen()
  const [_2, setFeaturePlansTooltipOpen] = useFeaturePlansTooltipOpen()

  const navigate = useNavigate()

  useEffect(() => {
    // on the callback redirect, exchange the Github App code for an access token
    if (code && installationId && setupAction === 'install') {
      const doAsync = async () => {
        // hide the welcome dialog while we're exchanging the code so that the user can see the loading spinner
        setWelcomeDialogOpen(false)

        // clear the search params so that we don't retrigger the exchange code flow
        searchParams.delete('code')
        searchParams.delete('installation_id')
        searchParams.delete('setup_action')
        setSearchParams(searchParams)

        await exchangeGithubCodeForAccessToken({
          code,
          installationId,
        })

        navigate(DEFAULT_AUTHENTICATED_ROUTE)

        // TODO: SS only if the user hasn't already seen it
        setWelcomeDialogOpen(true)

        // open the features tooltip
        if (features.featurePlanPreview) {
          setFeaturePlansTooltipOpen(true)
        }
      }

      doAsync()
    }
  }, [
    code,
    exchangeGithubCodeForAccessToken,
    installationId,
    navigate,
    searchParams,
    setFeaturePlansTooltipOpen,
    setSearchParams,
    setWelcomeDialogOpen,
    setupAction,
  ])

  return <Loading />
}
