import { collection, getDocs, orderBy, query, where } from 'firebase/firestore'
import { FirestoreCollection, Repo } from 'types'

import { db } from '@/firebase'

export const getRepos = async (uid: string) => {
  const ref = collection(db, FirestoreCollection.Repos)
  const queryRef = query(ref, where('ownerId', '==', uid), orderBy('createdAt', 'asc'))

  const snapshot = await getDocs(queryRef)

  return snapshot.docs.map(doc => ({
    ...doc.data(),
    id: doc.id,
  })) as Repo[]
}
