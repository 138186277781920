import React, { ComponentPropsWithoutRef, forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'

type Props = ComponentPropsWithoutRef<'div'> & {
  size?: 'xs' | 'sm' | 'md'
}

export const Card = forwardRef(({ className = '', size = 'md', ...props }: Props, ref: any) => {
  return (
    <div
      ref={ref}
      className={twMerge(
        'bg-theme-background dark:bg-dark-theme-background border-theme-border dark:border-dark-theme-border w-full border',
        size === 'xs'
          ? 'rounded-xl px-4 py-2 shadow-sm'
          : size === 'sm'
          ? 'rounded-2xl p-4 shadow'
          : 'rounded-2xl p-8 shadow-md',
        className,
      )}
      {...props}
    />
  )
})
