import { motion } from 'framer-motion'
import React, { Children, ComponentPropsWithoutRef, useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { Steps } from '../steps/Steps'

enum Direction {
  Right = 1,
  Left = -1,
}

type Props = ComponentPropsWithoutRef<'div'> & {
  activeIndex: number
  onSlideChange: (index: number) => void
}

export const Slider = ({ className = '', activeIndex, children, onSlideChange, ...props }: Props) => {
  // we need to keep track of direction so that we can animate the slides in the correct direction
  const [previousIndex, setPreviousIndex] = useState(activeIndex)

  useEffect(() => {
    setPreviousIndex(activeIndex)
  }, [activeIndex])

  const numberOfSlides = Children.count(children)
  const childAtActiveIndex = Children.toArray(children)[activeIndex]
  const direction = activeIndex > previousIndex ? Direction.Right : Direction.Left

  return (
    <>
      <div className="flex justify-center">
        <Steps>
          {Array.from({ length: numberOfSlides }).map((_, index) => (
            <Steps.Step
              key={index}
              active={activeIndex === index}
              disabled={index > activeIndex} // can go backwards but not forwards
              onClick={() => onSlideChange(index)}
            />
          ))}
        </Steps>
      </div>

      <div {...props} className={twMerge('', className)}>
        <motion.div
          key={activeIndex}
          initial={{ opacity: 0, scaleY: 0.9, translateX: 100 * direction }}
          animate={{ opacity: 1, translateX: 0, scaleY: 1 }}
          exit={{ opacity: 0, scaleY: 0.9, translateX: -100 * direction }}
          transition={{
            ease: 'anticipate',
          }}
        >
          {childAtActiveIndex}
        </motion.div>
      </div>
    </>
  )
}
