export const FEATURE_PLAN_ID_PARAM = ':featurePlanId'
export const ISSUE_ID_PARAM = ':issueId'
export const NEW_ISSUE_ID = 'new'
export const TEAM_ID_PARAM = ':teamId'
export const TEAM_MEMBER_ID_PARAM = ':teamMemberId'

export const routes = {
  back: -1,
  signUp: '/sign-up',
  signIn: '/sign-in',
  forgotPassword: '/forgot-password',
  userManagement: '/user-management',
  dashboard: '/',
  oAuthGithub: '/oauth/github',
  featurePlans: '/feature-plans',
  featurePlanCreate: '/feature-plans/create',
  featurePlan: `/feature-plans/${FEATURE_PLAN_ID_PARAM}`,
  featurePlanIssueEdit: `/feature-plans/${FEATURE_PLAN_ID_PARAM}/issues/${ISSUE_ID_PARAM}/edit`,
  featurePlanIssueEditBlockedBy: `/feature-plans/${FEATURE_PLAN_ID_PARAM}/issues/${ISSUE_ID_PARAM}/blocked-by`,
  featurePlanIssueDelete: `/feature-plans/${FEATURE_PLAN_ID_PARAM}/issues/${ISSUE_ID_PARAM}/delete`,
  settings: '/settings',
  settingsAccount: '/settings/account',
  settingsSubscription: '/settings/subscription',
  settingsTeam: `/settings/teams/${TEAM_ID_PARAM}`,
  settingsDeleteTeam: `/settings/teams/${TEAM_ID_PARAM}/delete`,
  settingsInviteTeamMembers: `/settings/teams/${TEAM_ID_PARAM}/invite`,
  settingsTeamMember: `/settings/teams/${TEAM_ID_PARAM}/members/${TEAM_MEMBER_ID_PARAM}`,
  settingsRemoveTeamMember: `/settings/teams/${TEAM_ID_PARAM}/members/${TEAM_MEMBER_ID_PARAM}/remove`,
  settingsIntegrations: '/settings/integrations',
}
