import { Dialog, Slider } from 'components'
import React, { useCallback } from 'react'

import { useWelcomeDialogIndex } from '@/onboarding/hooks/useWelcomeDialogIndex'
import { useWelcomeDialogOpen } from '@/onboarding/hooks/useWelcomeDialogOpen'

import { ConnectProjectManagementToolSlide } from './components/ConnectProjectManagementToolSlide'
import { ConnectRepoSlide } from './components/ConnectRepoSlide'
import { UsageSlide } from './components/UsageSlide'
import { WelcomeSlide } from './components/WelcomeSlide'

export const WelcomeDialog = () => {
  const [open, setWelcomeDialogOpen] = useWelcomeDialogOpen()
  const [activeIndex, setActiveIndex] = useWelcomeDialogIndex()

  const onContinue = useCallback(() => {
    const isLastSlide = activeIndex === 3

    if (isLastSlide) {
      setWelcomeDialogOpen(false)

      return
    }

    setActiveIndex(activeIndex + 1)
  }, [activeIndex, setActiveIndex, setWelcomeDialogOpen])

  return (
    <Dialog open={open}>
      <Slider activeIndex={activeIndex} onSlideChange={(index: number) => setActiveIndex(index)}>
        <WelcomeSlide onContinue={onContinue} />

        <ConnectRepoSlide onContinue={onContinue} />

        <ConnectProjectManagementToolSlide onContinue={onContinue} />

        <UsageSlide onContinue={onContinue} />
      </Slider>
    </Dialog>
  )
}
