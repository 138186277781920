import { Functions } from 'types'

import { invokeFunction } from '@/firebase/invokeFunction'

const exchangeGithubCodeForAccessTokenFunction = invokeFunction(Functions.exchangeGithubCodeForAccessToken)

export const exchangeGithubCodeForAccessToken = async ({
  code,
  installationId,
}: {
  code: string
  installationId: string
}) => {
  await exchangeGithubCodeForAccessTokenFunction({
    code,
    installationId,
  })
}
