import { Issue } from 'types'

export const formatIssuePriority = (priority: Issue['priority']) => {
  switch (priority) {
    case 0:
      return 'No Priority'
    case 1:
      return 'Urgent'
    case 2:
      return 'High'
    case 3:
      return 'Normal'
    case 4:
      return 'Low'
    default:
      return String(priority)
  }
}
