import { Button, Dialog } from 'components'
import React from 'react'

import { SlideContent } from './SlideContent'

type Props = {
  onContinue: () => void
}

// TODO: illustration
export const WelcomeSlide = ({ onContinue }: Props) => {
  return (
    <SlideContent
      title="Welcome to Potion, Shaun 👋🏻"
      description={
        <>
          Meet Potion, your personal AI wizard ready to transform your high-level feature ideas into actionable,
          prioritized issues ready for implementation by any developer.
        </>
      }
    >
      <Dialog.Actions>
        <Button size="lg" onClick={onContinue}>
          Let's Get Started!
        </Button>
      </Dialog.Actions>
    </SlideContent>
  )
}
